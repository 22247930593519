body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width:100vw;
  max-width:100%;
  min-height:100vh;
  padding:0;
  text-align: center;
  background-color: #F7FBFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: #F7FBFF; 
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #B2D1FF; 
  border-radius: 10px;
  height: 10%;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7EB2FF; 
}
