.footer {
    width: 100%;
    min-height: 50px;
}

.header {
    width: 100%;
    min-height: 10px;
}

.register_container {
    width: 100%;
    height: 100%;
}

.registerGrid {
    width: 98vw;
    height: 98vh;
}

.link {
    text-decoration: none;
}