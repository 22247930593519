.home_page {
    width: 100%;
    max-width: 1000px;
    display: inline-block;
}

.link {
    text-decoration: none;
    color: inherit;
}

.optionsDiv {
    width: 100%;
    padding: 10px;
    margin-top: 200px;
}