.centering {
    position: relative;
    float: left;
    width: 100%;
}

.form_card {
    width: 90%;
    position: relative;
    float: left;
    margin-top: -50px;
    margin-left: 5%;
}

.btn_cont {
    position: relative;
    float: left;
    width: 100%;
    margin: 20px 0;
}

.btnLeft {
    position: relative;
    float: left;
}

.btnRight {
    position: relative;
    float: right;
}